import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { List, Edit, ChevronLeft, ChevronRight, XCircle, Save } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from "react-hook-form"
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'

// variables
import { SITENAME } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import TextSearchField from '../../components/TextSearchField';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiDELETE, _apiPUT, _apiPOST } from '../../controllers/Api';
import { _roleName } from '../../controllers/Helpers';


export default function ListWorkExperience(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());
  const location = useLocation()

  const fields = {
    limit: 15,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: '',
    // clientId: auth.response.role >= 10 ? auth.response.clientId : null
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(location.state ? location.state.request : fields)
  const [ requestAction, setRequestAction ] = React.useState(location.state ? location.state.request : fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])

  // form fields
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
  const [ id, setId ] = React.useState('new')
  const [ title, setTitle ] = React.useState('')


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _cancelUpdate = () => {
    setId('new')
    setTitle('')
  }


  //
  //
  const _update = (data) => {
    setId(data._id)
    setTitle(data.title)
  }


  //
  //
  const _delete = async (id) => {
    const del = await _apiDELETE('/list-work-experience/'+id);
    if(del.statusCode !== 200){
      toast.error(del.message);
    }else{
      toast.success(del.message);
      setReload(reload+1)
    }
  }


  //
  //
  const _submit = async () => {

    if(id==='new'){

      const insert = await _apiPOST('/list-work-experience/', {title:title})
      if(insert.statusCode !== 200){
        toast.error(insert.message);
      }else{
        toast.success(insert.message);
        setId('new')
        setTitle('')
        setReload(reload+1)
      }

    }else{

      const update = await _apiPUT('/list-work-experience/'+id, {title:title})
      if(update.statusCode !== 200){
        toast.error(update.message);
      }else{
        toast.success(update.message);
        setId('new')
        setTitle('')
        setReload(reload+1)
      }

    }

  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      const res = await _apiGETmulti('/list-work-experience/query', requestAction)
      if(!res.error){
        setTotal(res.total)
        setData(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <button type="button" className="iconButton" onClick={()=>_update(data)}><Edit size={20}/></button>
        <button type="button" className="iconButton" onClick={()=>_delete(data._id)}><XCircle size={20} className="text-danger"/></button>
      </div>
    )

  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Work experience | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Work experience"
        icon={<List size={52}/>}
      >
        <TextSearchField
          type="text"
          name="search"
          value={request.search}
          onChange={(e)=>_updateField('search',e)}
          placeholder="search..."
        />
      </PageHead>

      <div className="relative row w-100 no-gutters">
        <div className="col-md-4">
          <div className="card mt-2 mb-4">
            <h4>{id==='new' ? 'Add' : 'Update'} work experience</h4>
            <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
              <div className="form-group">
                <label>Title</label>
                <input
                  {...register("title", { required: false })}
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e)=>setTitle(e.target.value)}
                  className={`form-control`}
                />
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center">
              <button className="button accent lg" disabled={title === '' ? true : false}><Save/>save</button>
              {id !== 'new' && <button type="button" className="button accent outline lg" onClick={()=>_cancelUpdate()}>cancel</button>}
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-8 pl-4">
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data vailable.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'Title', width:'auto', sortable:true, key:'title'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }
        </div>
      </div>
      <Loading status={loading}/>

    </Fragment>
  );

}
