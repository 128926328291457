import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { UserCheck, Eye, ChevronLeft, ChevronRight, XCircle } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import fileDownload from 'js-file-download'

// variables
import { SITENAME } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import TextSearchField from '../../components/TextSearchField';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiDELETE } from '../../controllers/Api';
import { _roleName } from '../../controllers/Helpers';


export default function Candidates(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());
  const location = useLocation()

  const fields = {
    limit: 15,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: '',
    group: ''
    // clientId: auth.response.role >= 10 ? auth.response.clientId : null
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(location.state ? location.state.request : fields)
  const [ requestAction, setRequestAction ] = React.useState(location.state ? location.state.request : fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])
  const [ page, setPage ] = React.useState(1)

  const [ _groups, setGroups ] = React.useState([])


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    setPage(e.selected)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _export = async() => {
    setLoading(true);
    const get = await _apiGETmulti('/candidates/export', requestAction, true)

    if(!get.error){
      fileDownload(get, 'export.csv');
    }
    setLoading(false);
  }


  //
  //
  const _delete = async (id) => {
    const del = await _apiDELETE('/users/'+id);
    if(del.statusCode !== 200){
      toast.error(del.message);
    }else{
      toast.success(del.message);
      setReload(reload+1)
    }
  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    if(requestAction.search !== ''){
      requestAction.offset = 0
    }

    const getData = async () =>{
      const res = await _apiGETmulti('/candidates/query', requestAction)
      if(!res.error){
        setTotal(res.total)
        setData(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload])


  //
  //
  React.useEffect(()=>{
    const getGroups = async()=>{
      const gl = await _apiGETmulti('/list-groups/query', { limit:200 },false)
      if(!gl.error){
        setGroups(gl.data)
      }
    }
    getGroups()
  },[])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <Link to={{pathname:'/candidates/'+data._id, state:{request:request, pathname:location.pathname}}} className="iconButton"><Eye size={20}/></Link>
        <button type="button" className="iconButton" onClick={()=>_delete(data._id)}><XCircle size={20} className="text-danger"/></button>
      </div>
    )

  }


  //
  //
  const _role = (data) => {
    return _roleName(data.role)
  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Candidates | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Candidates"
        icon={<UserCheck size={52}/>}
      >

        <div className="col-4 d-flex justify-content-end">
          <select
              type="text"
              name="group"
              value={request.group}
              onChange={(e)=>_updateField('group',e.target.value)}
              className={`form-control`}
            >
              <option value="">Sve grupe</option>
            {
              _groups.map((item,index)=>{
                return <option value={item._id} key={index}>{item.title}</option>
              })
            }
          </select>
        </div>

        {data.length>0 &&
          <div className="col-3 d-flex justify-content-end">
            <button type="button" className="button accent py-3" onClick={()=>_export()}>Export CSV</button>
          </div>
        }
        <TextSearchField
          type="text"
          name="search"
          value={request.search}
          onChange={(e)=>_updateField('search',e)}
          placeholder="search..."
        />
      </PageHead>

      <div className="relative w-100">
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data vailable.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'First name', width:'15%', sortable:true, key:'firstName'},
                {name:'Last name', width:'15%', sortable:true, key:'lastName'},
                {name:'Email', width:'auto', sortable:true, key:'email'},
                {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={(page-1)}
          />
          </>
        }
        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
