import axios from 'axios';
import { ApiUrl } from '../configuration.js';
import {store} from './redux/Store'


export const API = (restricted=true, headers, cancel=null, config={}) => {
  const user = store.getState();

  let headersConfig = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    ...headers
  };
  if(restricted && user.auth.status){
    headersConfig = {...headersConfig, 'Authorization': 'Bearer '+user.auth.response.token}
  }

  let axiosConfig = {
    baseURL: ApiUrl,
    headers: headersConfig,
    cancelToken: cancel?.token,
    ...config
  }

  // console.log('axiosConfig',axiosConfig)

  return axios.create(axiosConfig);
}


export const APICancel = () => {
  return axios.CancelToken.source()
}



//
//
export const _apiLogin = async (data) =>{

  const cancel = APICancel()

  return await API(false, {}, cancel)
  .post('/auth/login', data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}



//
//
export const _apiPOST = async (route, data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .post(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiPUT = async (route, data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .put(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiDELETE = async (route, header={}) =>{

  const cancel = APICancel()

  return await API(true, header, cancel)
  .delete(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiGETone = async (route) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiGETmulti = async (route, data) =>{

  const cancel = APICancel()

  return await API(true, {
    'X-Meta': JSON.stringify(data)
  }, cancel)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}



//
//
export const _apiAvailability = async (type,data) =>{

  const cancel = APICancel()
  let route = ''

  if(type==='email'){
    route = '/helpers/check-email/'
  }else if(type==='username'){
    route = '/helpers/check-username/'
  }else if(type==='docKey'){
    route = '/helpers/check-template-doc-key/'
  }else if(type==='emailKey'){
    route = '/helpers/check-template-email-key/'
  }

  return await API(true, {}, cancel)
  .post(route, data)
  .then(response => {
    return true
  }).catch(error => {
    return false;
  });

}




//
//
export const _apiGetFile = async (route, data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel, {responseType: 'blob'})
  .post(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}



//
//
export const _apiGetStaticFile = async (route, data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel, {responseType: 'blob'})
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}



////////////////////////////////////////////////

//
//
export const _apiLoginStatus = async () =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .get('/auth')
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}



//
//
export const _apiGetUsers = async (data) =>{

  const cancel = APICancel()

  return await API(true, {
    'X-Meta': JSON.stringify(data)
  }, cancel)
  .get('/users/query')
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}



//
//
export const _apiInsertUser = async (data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .post('/users/',data)
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}



//
//
export const _apiUpdateUser = async (id,data) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .put('/users/update/'+id, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}



///////////////////////////////////////////////

//
//
export const _apiGetMembers = async (data) =>{

  const cancel = APICancel()

  return await API(true, {
    'X-Meta': JSON.stringify(data)
  }, cancel)
  .get('/members/query')
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}



//
//
export const _apiGetMemberById = async (id) =>{

  const cancel = APICancel()

  return await API(true, {}, cancel)
  .get('/members/'+id)
  .then(response => {
    return response.data
  }).catch(error => {
    return false;
  });

}
