import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { BookOpen, Save, ChevronLeft, UploadCloud } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Lightbox from 'react-image-lightbox'

// variables
import { SITENAME, ApiUrl, UploadUrl, _educationStatus } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import ContentEditorBasic from '../../components/ContentEditor/basic'

// controllers
import _actions from '../../controllers/redux/actions'
import { API, _apiGETone, _apiPOST, _apiPUT, _apiDELETE } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function EducationsForm(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    status: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm()

  const [lightbox, setLightbox] = React.useState(false)
  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const [images, setImages] = React.useState([]);



  //
  //
  const _openLightBox = (index) => {
    setLightboxIndex(index)
    setLightbox(true)
  }


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _handleEditorChange = (content, editor) => {
    setFormData({...formData, content:content})
  }


  //
  //
  const _submit = async () => {

    const update = await _apiPUT('/educations/'+id, formData)
    if(update.statusCode !== 200){
      toast.error(update.message);
    }else{
      toast.success(update.message);
      history.push('/educations/')
    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone('/educations/'+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          setFormData({...res})
          setValue('status', res.status)
          // setValue('title', res.title)
          // setValue('content', res.content)
          // setValue('dateStart', res.dateStart)
          // setValue('dateEnd', res.dateEnd)
          // setValue('duration', res.duration)
          // setValue('price', res.price)
          // setValue('locationTitle', res.locationTitle)
          // setValue('locationAddress', res.locationAddress)
          // // setValue('locationMap', res.locationMap)
          // setValue('featuredImage', res.featuredImage)
          // setValue('gallery', res.gallery)

          if(res.gallery && res.gallery.length>0 && images.length===0){
            let img = []
            res.gallery.map((item,index)=>{
              img.push(UploadUrl+'/images/'+item.fileName)
            })
            setImages(img)
          }
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[id, setValue])



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Educations | { SITENAME }</title>
      </Helmet>

      <PageHead
        title={`Educations | ${formData ? formData.title : ''}`}
        icon={<BookOpen size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
      </PageHead>

      <div className="relative w-100">

        <div className="row">
          <div className="col-12">



          {!loading && formData &&
          <div className="row">
            <div className="col-lg-8">
              <div className="form-group">
                <label>Title</label>
                <p className="d-flex align-items-center"><span className={`status ${formData.status} mr-2`}>{formData.status}</span> {formData.title}</p>
              </div>
              <div className="form-group">
                <label>Company</label>
                <p><strong>{formData.company.employeer.companyName}</strong></p>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Date created</label>
                    <p>{dayjs(formData.dateCreated).format('DD.MM.YYYY')}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Date expire</label>
                    <p>{dayjs(formData.dateExpire).format('DD.MM.YYYY')}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Price</label>
                    <p>{formData.price}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Location</label>
                    <p>{formData.location ? formData.location.description : 'N/A'}</p>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Description</label>
                <p dangerouslySetInnerHTML={{__html:formData.content}} />
              </div>

              {images.length>0 &&
                <div className="mt-3">
                  <p><strong>Multimedia</strong></p>

                  <div className="gallery">
                  {
                    images.map((item,index)=>{
                      return (
                        <div className="item" onClick={()=> _openLightBox(index)} key={index} style={{backgroundImage:`url(${item})`}}></div>
                      )
                    })
                  }
                  </div>
                </div>
                }

              </div>
              <div className="col-lg-4">
                <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
                  <div className="card mt-2 mb-4">
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        {...register("status", { required: true })}
                        type="text"
                        name="status"
                        value={formData.status}
                        onChange={(e)=>_updateField('status',e.target.value)}
                        className={`form-control ${errors.status && 'error'}`}
                      >
                      {
                        _educationStatus.map((item,index)=>{
                          return <option value={item.value} key={index}>{item.label}</option>
                        })
                      }
                      </select>
                      {errors.status && <div className="errorField">This field is required</div>}
                    </div>
                    <div className="form-group mb-0">
                      <button className="button accent lg fullWidth"><Save/>save</button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
            }

        </div>
        </div>

        {lightbox && (
          <Lightbox
            mainSrc={images[lightboxIndex]}
            nextSrc={images[(lightboxIndex + 1) % images.length]}
            prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setLightbox(false)}
            onMovePrevRequest={() =>
              setLightboxIndex((lightboxIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setLightboxIndex((lightboxIndex + 1) % images.length)
            }
          />
        )}

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
