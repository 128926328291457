import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { UserCheck, ChevronLeft, Save } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

// variables
import { UploadUrl, SITENAME, _drivingLicence, _workExperience, _languageSkills, _computerSkills } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPUT, _apiGETmulti, _apiGetStaticFile } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function CandidatesDetails(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    status: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ data, setData ] = React.useState(null)
  const [ uploadedDocuments, setUploadedDocuments ] = React.useState([])

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  const _download = async(id, fileName) => {

    const file = await _apiGetStaticFile('/files/'+id);
    if(file){
      toast.success('Dokument '+fileName+' preuzet.');
      fileDownload(file, fileName);
    }

  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone('/candidates/'+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          // setFormData({...res})
          setData(res)
          // setValue('status', res.status)
          // setValue('lastName', res.lastName)
          // setValue('username', res.username)
          // setValue('email', res.email)
          // setValue('phone', res.phone)
          // setValue('role', res.role)

          const documents = await _apiGETmulti('/files/query/'+res._id, {}, true)
          if(!documents.error){
            setUploadedDocuments(documents.data)
          }
        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[id, setValue])


  //
  //
  const _submit = async () => {

    const update = await _apiPUT('/jobs/'+id, formData)
    if(update.statusCode !== 200){
      toast.error(update.message);
    }else{
      toast.success(update.message);
      history.push('/jobs/')
    }

  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Candidates | { SITENAME }</title>
      </Helmet>


      <PageHead
        title={`Candidates | ${data ? data.firstName+' '+data.lastName : ''}`}
        icon={<UserCheck size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
      </PageHead>

      <div className="relative w-100">

        {!loading && data &&
        <div className="row">
          <div className="col-lg-8">

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>First name</label>
                  <p>{data.firstName}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Last name</label>
                  <p>{data.lastName}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Email</label>
                  <p>{data.email}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Phone</label>
                  <p>{data.candidate ? data.candidate.phone : 'N/A'}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="form-group">
                  <label>Location</label>
                  <p>{data.candidate && data.candidate.location ? data.candidate.location.description : 'N/A'}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Qualifications</label>
                  <p>{data.candidate ?data.candidate.qualifications : 'N/A'}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Profession</label>
                  <p>{data.candidate ? data.candidate.profession : 'N/A'}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Computer skill</label>
                  <p>
                  {
                    data.candidate ? _computerSkills.map((item, index) => {
                      if(item.value===data.candidate.computerSkills){
                        return item.name
                      }
                    })
                    :
                    'N/A'
                  }
                  </p>
                </div>
              </div>
            </div>


            <div className="form-group">
              <label>Driving licence</label>
              {
                  data.candidate ? _drivingLicence.map((item,index)=>{
                    const dl =  data.candidate.drivingLicence ? data.candidate.drivingLicence : []
                    const findIndex = Object.keys(dl).findIndex(x => {return x===item.value})
                    const isChecked = Object.values(dl)[findIndex]
                    if(isChecked){
                      return <span key={index} className="mr-3">{item.name}</span>
                    }
                  })
                  :
                  'N/A'
                }
            </div>

            <div className="form-group">
              <label>Language skill</label>
              {
                  data.candidate ? _languageSkills.map((item,index)=>{
                    const dl =  data.candidate.languageSkills ? data.candidate.languageSkills : []
                    const findIndex = Object.keys(dl).findIndex(x => {return x===item.value})
                    const isChecked = Object.values(dl)[findIndex]
                    if(isChecked){
                      return <span key={index} className="mr-3">{item.name}</span>
                    }
                  })
                  :
                  'N/A'
                }
            </div>

            {data.candidate && data.candidate.videoUrl && data.candidate.videoUrl !== '' &&
              <div className="form-group">
                <label>Video presentation (url)</label>
                <p><a href={data.candidate.videoUrl} target={'_blank'}>{data.candidate.videoUrl}</a></p>
              </div>
            }

            <div className="form-group">
              <label>Documents</label>
              {uploadedDocuments.length>0 && uploadedDocuments.map((item,i)=>{
                  const ext = item.fileName.substr(item.fileName.lastIndexOf('.') + 1);
                  return (
                    <div className="py-2 border-top" key={i}>
                    <div className="row flex align-items-center">
                      <div className="col-md-9 d-flex align-items-center"><span className={`badge ${ext} mr-2`}>{ext}</span> <strong>{item.fileName}</strong></div>
                      <div className="col-md-3 d-flex justify-content-end">
                        <button type="button" onClick={()=> _download(item._id, item.fileName)} className="button">Download</button>
                      </div>
                    </div>
                    </div>
                  )
                })
              }
            </div>

          </div>

          <div className="col-lg-4">
            <div className="card mt-2 mb-4">
              {data.profileImage &&
                <div className="form-group">
                <img src={UploadUrl+'/images/'+data.profileImage.baseName+'_500.jpg'} alt="" layout="fill" />
                </div>
              }
              <div className="form-group mb-0">
                <label>Date created</label>
                <p>{dayjs(data.dateCreated).format('DD.MM.YYYY')}</p>
              </div>
              <div className="form-group mb-0">
                <label>Date last activity</label>
                <p>{dayjs(data.dateLastActivity).format('DD.MM.YYYY')}</p>
              </div>
            </div>
          </div>
          {/*
          <div className="col-lg-4">
            <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
              <div className="card mt-2 mb-4">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    {...register("status", { required: true })}
                    type="text"
                    name="status"
                    value={formData.status}
                    onChange={(e)=>_updateField('status',e.target.value)}
                    className={`form-control ${errors.status && 'error'}`}
                  >
                  {
                    _jobStatus.map((item,index)=>{
                      return <option value={item.value} key={index}>{item.label}</option>
                    })
                  }
                  </select>
                  {errors.status && <div className="errorField">This field is required</div>}
                </div>
                <div className="form-group mb-0">
                  <button className="button accent lg fullWidth"><Save/>save</button>
                </div>
              </div>
            </form>
          </div>
          */}
        </div>
        }

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
