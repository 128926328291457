import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Users, ChevronLeft, Save } from 'react-feather'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

// variables
import { UploadUrl, SITENAME, _drivingLicence, _workExperience, _languageSkills, _computerSkills } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETone, _apiPUT, _apiGETmulti } from '../../controllers/Api';
import { _goBack } from '../../controllers/Helpers';


export default function CandidatesDetails(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const isBackPossible = _goBack(location)

  const fields = {
    status: ''
  }

  const [ loading, setLoading ] = React.useState(true);
  const [ formData, setFormData ] = React.useState(fields)
  const [ data, setData ] = React.useState(null)

  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();


  //
  //
  const _updateField = (field, data) => {
    setFormData({...formData, [field]:data});
  }


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    const getData = async () =>{
      if(id !== 'new'){
        const res = await _apiGETone('/employers/'+id)
        if(!res._id){
          toast.error(res.message);
        }else{
          // setFormData({...res})
          setData(res)
          // setValue('status', res.status)
          // setValue('lastName', res.lastName)
          // setValue('username', res.username)
          // setValue('email', res.email)
          // setValue('phone', res.phone)
          // setValue('role', res.role)

        }
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[id, setValue])


  //
  //
  const _submit = async () => {

    const update = await _apiPUT('/jobs/'+id, formData)
    if(update.statusCode !== 200){
      toast.error(update.message);
    }else{
      toast.success(update.message);
      history.push('/jobs/')
    }

  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Employeers | { SITENAME }</title>
      </Helmet>


      <PageHead
        title={`Employeers | ${data ? data.employeer.companyName : ''}`}
        icon={<Users size={52}/>}
      >
        {isBackPossible && <Link to={isBackPossible} className="button accent lg outline ml-1"><ChevronLeft/> back</Link>}
      </PageHead>

      <div className="relative w-100">

        {!loading && data &&
        <div className="row">
          <div className="col-lg-8">

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Company name</label>
                  <p>{data.employeer.companyName}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Company slogan</label>
                  <p>{data.employeer.companySlogan}</p>
                </div>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>First name</label>
                  <p>{data.firstName}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Last name</label>
                  <p>{data.lastName}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Email</label>
                  <p>{data.email}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label>Phone</label>
                  <p>{data.employeer.phone}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="form-group">
                  <label>Location</label>
                  <p>{data.employeer.location && data.employeer.location.description && data.employeer.location.description}</p>
                </div>
              </div>
            </div>

            {data.employeer.website && data.employeer.website !== '' &&
              <div className="form-group">
                <label>Website</label>
                <p><a href={data.employeer.website} target={'_blank'}>{data.employeer.website}</a></p>
              </div>
            }

            <div className="form-group">
              <label>Description</label>
              <p dangerouslySetInnerHTML={{__html:data.employeer.description}} />
            </div>

          </div>

          <div className="col-lg-4">
            <div className="card mt-2 mb-4">
              {data.profileImage &&
                <div className="form-group">
                <img src={UploadUrl+'/images/'+data.profileImage.baseName+'_500.jpg'} alt="" layout="fill" />
                </div>
              }
              <div className="form-group mb-0">
                <label>Date created</label>
                <p>{dayjs(data.dateCreated).format('DD.MM.YYYY')}</p>
              </div>
              <div className="form-group mb-0">
                <label>Date last activity</label>
                <p>{dayjs(data.dateLastActivity).format('DD.MM.YYYY')}</p>
              </div>
            </div>
          </div>
          {/*
          <div className="col-lg-4">
            <form onSubmit={handleSubmit(_submit)} autoComplete="off" className="w-100">
              <div className="card mt-2 mb-4">
                <div className="form-group">
                  <label>Status</label>
                  <select
                    {...register("status", { required: true })}
                    type="text"
                    name="status"
                    value={formData.status}
                    onChange={(e)=>_updateField('status',e.target.value)}
                    className={`form-control ${errors.status && 'error'}`}
                  >
                  {
                    _jobStatus.map((item,index)=>{
                      return <option value={item.value} key={index}>{item.label}</option>
                    })
                  }
                  </select>
                  {errors.status && <div className="errorField">This field is required</div>}
                </div>
                <div className="form-group mb-0">
                  <button className="button accent lg fullWidth"><Save/>save</button>
                </div>
              </div>
            </form>
          </div>
          */}
        </div>
        }

        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
