//
//
export const _checkRole = (roles, current) => {

  if(!roles){
    return true
  }

  const available = roles.filter(x => current===x);
  if(available.length>0){
    return true
  }

  return false
}


//
//
export const _roleName = (role) => {

  const roles = [
    {id:1, name:'Super administrator'},
    {id:2, name:'Administrator'},
    {id:3, name:'Moderator'},
    {id:10, name:'Owner'},
    {id:11, name:'Employeer'}
  ]

  const index = roles.findIndex(x => x.id===role);
  if(index !== -1){
    return roles[index].name
  }else{
    return 'N/A'
  }

}



//
//
export const _labelByValue = (value, list) => {
  const index = list.findIndex(x => x.value===value);
  if(index !== -1){
    return list[index].label
  }
}


//
//
export const _itemByValue = (value, list, field='value') => {
  const index = list.findIndex(x => x[field]===value);
  if(index !== -1){
    return list[index]
  }
}


//
//
export const _goBack = (location) => {
  if(location.state && location.state !== ''){
    return {pathname:location.state.pathname, state: location.state}
  }else{
    return false;
  }
}


//
// user/member initials for avatar
export const _initials = (user) => {

  let initials = '';
  if (user.firstName) {
    if (user.firstName.length > 1) {
      initials = user.firstName.charAt(0);
    }
  }
  if (user.lastName) {
    if (user.lastName.length > 1) {
      initials = (initials.length > 0 ? initials + ' ' : null) + user.lastName.charAt(0);
    }
  }
  return initials;

}



//
//
export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}


//
//
export const _returnMaxStep = (data) => {

  let step = 0;

  if(data){
    if(data.start && Object.keys(data.start).length>0){
      step=1
    }
    if(data.company && Object.keys(data.company).length>0 && data.founders && data.founders.length>0){
      step=2
    }
    if(data.company && Object.keys(data.company).length>0 && data.founders && data.founders.length>0 && data.status>4){
      step=3
    }
    if(data.company && Object.keys(data.company).length>0 && data.founders && data.founders.length>0 && data.company.dateStatus && data.status>5){
      step=4
    }
    if(data.company && Object.keys(data.company).length>0 && data.founders && data.founders.length>0 && data.company.dateCreation && data.status>6){
      step=5
    }
  }

  return step;

}
