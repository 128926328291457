import {
  BarChart2,
  Briefcase,
  BookOpen,
  Youtube,
  // Copy,
  List,
  Users,
  UserCheck,
  Shield
} from 'react-feather';

// export const ApiUrl = 'http://127.0.0.1:7003';
export const ApiUrl = 'https://zacon.reanode.com';
export const UploadUrl = 'https://zacon.reanode.com/uploads';

export const SITENAME = 'ZACON.BA';
export const SITEURL = 'https://zacon.ba';
export const DateFormat = 'DD MMM. YYYY HH:mm';


export const navItems = [
  {
    type:'title',
    title: 'Menu',
    link:null,
    role:null
  },
  {
    type:'link',
    title: 'Dashboard',
    link:'/',
    role:[1,2,3],
    icon: <BarChart2/>
  },
  {
    type:'link',
    title: 'Jobs',
    link:'/jobs/',
    role:[1,2,3],
    icon: <Briefcase/>
  },
  {
    type:'link',
    title: 'Educations',
    link:'/educations/',
    role:[1,2,3],
    icon: <BookOpen/>
  },
  // {
  //   type:'link',
  //   title: 'Podcasts',
  //   link:'/podcasts/',
  //   role:[1,2,3],
  //   icon: <Youtube/>
  // },
  // {
  //   type:'link',
  //   title: 'Templates',
  //   link:'/templates/documents/',
  //   role:[1,2,10,11],
  //   icon: <Copy/>,
  //   children:[
  //     {
  //       type:'link',
  //       title: 'Documents',
  //       link:'/templates/documents/',
  //       role:[1,2,10,11],
  //     },
  //     {
  //       type:'link',
  //       title: 'Emails',
  //       link:'/templates/emails/',
  //       role:[1,2,10,11],
  //     },
  //     {
  //       type:'link',
  //       title: 'Banks',
  //       link:'/templates/banks/',
  //       role:[10,11],
  //     },
  //     {
  //       type:'link',
  //       title: 'Registers',
  //       link:'/templates/registers/',
  //       role:[10,11],
  //     }
  //   ]
  // },
  {
    type:'link',
    title: 'Employeers',
    link:'/employeers/',
    role:[1,2,3],
    icon: <Users/>
  },
  {
    type:'link',
    title: 'Candidates',
    link:'/candidates/',
    role:[1,2,3],
    icon: <UserCheck/>
  },
  {
    type:'link',
    title: 'Category/Group',
    link:'/qualifications/groups/',
    role:[1,2,3],
    icon: <List/>
  },
  // {
  //   type:'link',
  //   title: 'Settings',
  //   link:'/settings/',
  //   role:[10,11],
  //   icon: <Settings/>
  // },
  // {
  //   type:'link',
  //   title: 'Qualifications',
  //   link:'/qualifications/groups/',
  //   role:[1,2,3],
  //   icon: <List/>,
  //   children:[
  //     {
  //       type:'link',
  //       title: 'Groups',
  //       link:'/qualifications/groups/',
  //       role:[1,2,3],
  //     },
  //     {
  //       type:'link',
  //       title: 'Categories',
  //       link:'/qualifications/categories/',
  //       role:[1,2,3],
  //     }
  //   ]
  // },
  {
    type:'link',
    title: 'Work experience',
    link:'/work-experience/',
    role:[1,2,3],
    icon: <List/>
  },
  {
    type:'link',
    title: 'Language skills',
    link:'/language-skills/',
    role:[1,2,3],
    icon: <List/>
  },
  {
    type:'link',
    title: 'Administrators',
    link:'/administrators/',
    role:[1,2],
    icon: <Shield/>
  },
]



export const _jobStatus = [
  {label:'Active', value:'active'},
  {label:'Pending', value:'pending'},
  {label:'Rejected', value:'rejected'},
  {label:'Expired', value:'expired'},
]

export const _educationStatus = [
  {label:'Active', value:'active'},
  {label:'Pending', value:'pending'},
  {label:'Rejected', value:'rejected'},
  {label:'Expired', value:'expired'},
]



export const _drivingLicence = [
  {name:'A1', value:'A1'},
  {name:'A', value:'A'},
  {name:'B1', value:'B1'},
  {name:'B', value:'B'},
  {name:'C1', value:'C1'},
  {name:'C', value:'C'},
  {name:'D1', value:'D1'},
  {name:'D', value:'D'},
  {name:'D1+E', value:'D1+E'},
  {name:'D+E', value:'D+E'},
  {name:'C1+E', value:'C1+E'},
  {name:'C+E', value:'C+E'}
]


export const _languageSkills = [
  {name:'English', value:'en'},
  {name:'Deutsch', value:'de'},
  {name:'French', value:'fr'}
]


export const _computerSkills = [
  {name:'Ne', value:'none'},
  {name:'Osnovni', value:'level1'},
  {name:'Srednji', value:'level2'},
  {name:'Napredni', value:'level3'}
]


export const _workExperience = [
  {name:'Ne', value:'none'},
  {name:'do 1 godine', value:'1'},
  {name:'2 - 3 godine', value:'2'}
]
