import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet'
import { Briefcase, Eye, ChevronLeft, ChevronRight, XCircle, Edit } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'

// variables
import { SITENAME, SITEURL } from '../../configuration.js';

// components
import PageHead from '../../components/PageHead';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import TextSearchField from '../../components/TextSearchField';

// controllers
import _actions from '../../controllers/redux/actions'
import { _apiGETmulti, _apiDELETE } from '../../controllers/Api';
import { _roleName } from '../../controllers/Helpers';


export default function Jobs(props){

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());
  const location = useLocation()

  const fields = {
    limit: 15,
    offset: 0,
    orderBy: 'dateCreated|desc',
    search: '',
    clientId: auth.response.role >= 10 ? auth.response.clientId : null
  }

  const [ reload, setReload ] = React.useState(1);

  const [ loading, setLoading ] = React.useState(true);
  const [ request, setRequest ] = React.useState(location.state ? location.state.request : fields)
  const [ requestAction, setRequestAction ] = React.useState(location.state ? location.state.request : fields)
  const [ total, setTotal ] = React.useState(0)
  const [ data, setData ] = React.useState([])


  //
  //
  const _paginateAction = (e) => {
    const offset = e.selected === 0 ? 0 : (e.selected*fields.limit)
    _updateField('offset',offset)
  }



  //
  //
  const _updateField = (field, data) => {
    setRequest({...request, [field]:data});
  }


  //
  //
  const _delete = async (id) => {
    const del = await _apiDELETE('/jobs/'+id);
    if(del.statusCode !== 200){
      toast.error(del.message);
    }else{
      toast.success(del.message);
      setReload(reload+1)
    }
  }


  //
  //
  React.useEffect(() => {
    const timeoutId = setTimeout(() => request !== '' ? setRequestAction(request) : null, 500 );
    return () => clearTimeout(timeoutId);
  }, [request]);


  //
  //
  React.useEffect(()=>{

    setLoading(true)

    if(requestAction.search !== ''){
      requestAction.offset = 0
    }

    const getData = async () =>{
      const res = await _apiGETmulti('/jobs/query', requestAction)
      if(!res.error){
        setTotal(res.total)
        setData(res.data)
      }
      setLoading(false)
    }
    getData()

    return (() => [getData])
  },[requestAction, reload])


  //
  //
  const _options = (data, index) => {

    return (
      <div className="d-flex justify-content-end align-items-center">
        <Link to={{pathname:'/jobs/'+data._id, state:{request:request, pathname:location.pathname}}} className="iconButton"><Edit size={20}/></Link>
        <a target={'_blank'} href={SITEURL+'/posao/'+data.permalink} className="iconButton"><Eye size={20}/></a>
        <button type="button" className="iconButton" onClick={()=>_delete(data._id)}><XCircle size={20} className="text-danger"/></button>
      </div>
    )

  }


  //
  //
  const _company = (data, index) => {

    if(data.company.employeer){
      return data.company.employeer.companyName
    }else{
      return '-'
    }

  }


  //
  //
  const _status = (data, index) => {

    return <span className={`status ${data.status}`}>{data.status}</span>

  }


  //
  //
  const _role = (data) => {
    return _roleName(data.role)
  }



  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Jobs | { SITENAME }</title>
      </Helmet>

      <PageHead
        title="Jobs"
        icon={<Briefcase size={52}/>}
      >
        <TextSearchField
          type="text"
          name="search"
          value={request.search}
          onChange={(e)=>_updateField('search',e)}
          placeholder="search..."
        />
      </PageHead>

      <div className="relative w-100">
        {total===0 ?
          <div className="text-center py-4">{loading ? 'loading...' : 'No data vailable.'}</div>
        :
          <>
          <Table
            action={_updateField}
            perPage={request.limit}
            sortBy={request.orderBy}
            head={
              [
                {name:'Status', width:'10%', sortable:true, key:'_', type:'function', func:_status},
                {name:'Title', width:'auto', sortable:true, key:'title'},
                {name:'Company', width:'20%', sortable:false, key:'_', type:'function', func:_company},
                {name:'Applications', width:'15%', sortable:false, key:'applicationsTotal'},
                {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date'},
                {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
              ]
            }
            body={data}
            total={total}
          />
          <ReactPaginate
            previousLabel={<ChevronLeft/>}
            nextLabel={<ChevronRight/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(total/fields.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={_paginateAction}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          </>
        }
        <Loading status={loading}/>
      </div>

    </Fragment>
  );

}
