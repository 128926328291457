import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';


// controllers
import _actions from './redux/actions'
import { _apiLoginStatus } from './Api';
import { inArray } from './Helpers';
import ScrollToTop from './ScrollToTop';


// components
import Loading from '../components/Loading';
import Layout from '../components/Layout';

// pages
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

import Jobs from '../pages/Jobs';
import JobDetails from '../pages/Jobs/details';

import Educations from '../pages/Educations';
import EducationsForm from '../pages/Educations/form';

// import Podcasts from '../pages/Podcasts';
// import PodcastsForm from '../pages/Podcasts/form';

import Candidates from '../pages/Candidates';
import CandidatesDetails from '../pages/Candidates/details';
import Employers from '../pages/Employers';
import EmployersDetails from '../pages/Employers/details';

import Administrators from '../pages/Administrators';
import AdministratorsForm from '../pages/Administrators/form';

import ListGroups from '../pages/Categories/groups';
import ListCategories from '../pages/Categories/categories';
import ListWorkExperience from '../pages/WorkExperience';
import ListLanguageSkills from '../pages/LanguageSkills';

import Page404 from '../pages/404';
import Page403 from '../pages/403';


function PrivateRoute({ children, ...rest }) {

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const permissionLevel = rest.role && auth.status ? inArray(auth.response.role, rest.role) : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.status ? (
          permissionLevel ?
          children :
          <Redirect
            to={{
              pathname: "/403",
              state: { from: location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function Router() {

  const dispatch = useDispatch();
  const auth = dispatch(_actions.auth.authStatus());

  const [ isLoggedIn, setIsLoggedIn ] = React.useState(auth.status);


  //
  //
  React.useEffect(()=>{

    const getStatus = async () =>{
      const status = await _apiLoginStatus()
      if(!status){
        setIsLoggedIn(false)
        dispatch(_actions.auth.authLogout())
      }else{
        dispatch(_actions.auth.authUpdate({...status}))
        setIsLoggedIn(true)
      }
    }
    if(auth.status){
      getStatus()
    }

    return (() => [getStatus])
  },[auth.status, dispatch])


  //
  //
  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop />
      <Suspense fallback={<Loading size="large" type="full"/>}>
        <Layout isLoggedIn={isLoggedIn} auth={auth}>

            <Switch>

              {!isLoggedIn &&
                <>
                <Route exact path="/">
                  <Redirect
                    to={{
                      pathname: "/login"
                    }}
                  />
                </Route>
                <Route exact path="/login/">
                  <Login/>
                </Route>
                </>
              }


              <PrivateRoute
                exact
                path="/"
                status={isLoggedIn}
              >
                <Dashboard/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/jobs/"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <Jobs/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/jobs/:id"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <JobDetails/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/educations/"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <Educations/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/educations/:id"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <EducationsForm/>
              </PrivateRoute>

              {/*
              <PrivateRoute
                exact
                path="/podcasts/"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <Podcasts/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/podcasts/:id"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <PodcastsForm/>
              </PrivateRoute>
              */}


              <PrivateRoute
                exact
                path="/employeers/"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <Employers/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/employeers/:id"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <EmployersDetails/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/candidates/"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <Candidates/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/candidates/:id"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <CandidatesDetails/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/qualifications/groups"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <ListGroups/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/qualifications/categories"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <ListCategories/>
              </PrivateRoute>LanguageSkills
              <PrivateRoute
                exact
                path="/work-experience"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <ListWorkExperience/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/language-skills"
                status={isLoggedIn}
                role={[1,2,3]}
              >
                <ListLanguageSkills/>
              </PrivateRoute>


              <PrivateRoute
                exact
                path="/administrators/"
                status={isLoggedIn}
                role={[1,2]}
              >
                <Administrators/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/administrators/:id"
                status={isLoggedIn}
                role={[1,2]}
              >
                <AdministratorsForm/>
              </PrivateRoute>


              <Route exact path="/404">
                <Page404/>
              </Route>
              <Route exact path="/403">
                <Page403/>
              </Route>


              <Route path="*">
                <Redirect
                  to={{
                    pathname: "/404"
                  }}
                />
              </Route>

            </Switch>

          <ToastContainer
            position="top-right"
          />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;

// <Route exact path="/:permalink">
//   <PageGeneric sidebar={_sidebar}/>
// </Route>
